import React, {Component} from "react";
import ARROW from "../media/down_arrow.svg";
import "./MediaSlideshowStyle.css";

class MediaSlideshow extends Component{

    constructor(props){
        super(props);
        this.state = {
            index: 0,
            media: this.props.sources
        }

        console.log(this.props.sources);

        this.isImage = ['.gif','.jpg','.jpeg','.png'];
        this.isVideo =['.mpg', '.mp2', '.mpeg', '.mpe', '.mpv', '.mp4'];
    }

    getMediaType=()=>{
        var type = "img";
        if (this.state.media == "") return type;
        for (var i = 0; i < this.isVideo.length; i++){
            if (this.state.media[this.state.index].includes(this.isVideo[i])){
                type = "video";
                return type;
            }
        }
        return type;
    }

    moveRight=()=>{
        this.state.index += 1;
        if (this.state.index >= this.state.media.length){
            this.state.index = 0;
        }
        this.setState({});
    }

    moveLeft=()=>{
        this.state.index -= 1;
        if (this.state.index < 0){
            this.state.index = this.state.media.length-1;
        }
        this.setState({});
    }

    render(){
        return <div className="media-slideshow">
            {this.getMediaType() == "img" ? 
            <img className="media-source" src={this.state.media[this.state.index]}/> 
            : <video className="media-source" controls key={this.state.media[this.state.index]}> 
                <source src={this.state.media[this.state.index]}/>
            </video>}
           
            {this.state.media.length > 1 ? <div className="media-controls">
                <div className="media-left" onClick={this.moveLeft}>
                    <img src={ARROW} />
                </div>
                <div className="media-count">{(this.state.index + 1)+"/"+this.state.media.length}</div>
                <div className="media-right" onClick={this.moveRight}>
                    <img src={ARROW} />
                </div>
            </div> : <div className="media-controls"><span>1/1</span></div>}
            
        </div>;
    }
}

export default MediaSlideshow;