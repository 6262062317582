import React, {Component, useState} from "react";
import "./ProjectStyle.css";

import BACK_ARROW from "./media/back-arrow.png";
import BOTTOM_BLOB from "./media/bottom_blob.svg";

import {db} from './firebase/Firebasedata';
import {doc, getDoc} from 'firebase/firestore';
import { useLocation, useNavigate } from "react-router-dom";

import MediaSlideshow from "./HomeComponents/MediaSlideshow";
import Skill from "./HomeComponents/Skill";

class Project extends Component{

    constructor(props){  
        super(props);
        this.state = {
            loadedProjectData: false,
            project: new Map(),
            skills: [],
            projectID:  props.location.state.projectID,
            type: "",
            embeddable_link: "",
            long_desc: [],
            imgs: []
        };
    }  

    componentDidMount () {
        window.scrollTo(0, 0);
        this.getProjects();
    }

    async getProjects(){
        const docSnapshot = await getDoc(doc(db,"Projects",this.state.projectID));
        var data = docSnapshot.data();
        this.setState({
            imgs: data.imgs,
            loadedProjects:true,
            project:data,
            skills:data.skills,
            type:data.type,
            embeddable_link:data.embeddable_link,
            long_desc: data.long_desc,
        },()=>{console.log(this.state.imgs);});
    }

    back = () => {
        this.props.navigate(-1);
    }

    render(){
        return <div className="project-body">
            <div className="nav-back" onClick={this.back}>
                <img className="back-arrow" src={BACK_ARROW} />
            </div>
            {/* <img className="bottom-blob-project" src={BOTTOM_BLOB}></img> */}
            {/* <img className="project-blob" src={BOTTOM_BLOB}></img> */}

            <div className="project-content">
                {this.state.project == null ? <div></div> : <div><div className="skills-used">
                    <h1 className="project-name">{this.state.project.name}</h1>
                    {this.state.skills.map((skill,i)=><Skill i={i} skill={skill}/>)}
                </div>
                {this.state.loadedProjects && this.state.type != "embeddable" ? 
                <MediaSlideshow className="media-slideshow-instance" sources={this.state.imgs}>
                </MediaSlideshow>  : <div></div>}              
                <p className="long-desc">{this.state.long_desc.map((p)=><span className="p-desc">{p}</span>)}</p></div>}

                {this.state.type == "embeddable" ? <iframe className="embeddable-iframe" key="iframe" src={this.state.embeddable_link} width={1000} height={500}/> : <div></div>} 
            </div>
        </div>
    }
}


export default function(props){
    const location = useLocation();
    const navigate = useNavigate();
    return <Project {...props} location={location} navigate={navigate} />;
}