import React, {Component} from "react";
import BOTTOM_BLOB from "./media/bottom_blob.svg";
import "./AboutMeStyle.css";
import DOWN_ARROW from "./media/down_arrow.svg";

class AboutMe extends Component{

    constructor(props){
        super(props);
    }

    scrollToAboutMe(){
        var element = document.getElementById("projects");
        window.scrollTo({
            top:element.offsetTop,
            behavior:"smooth"
        });
    }

    render(){
        return <div id="about" className="about-me-body">
            {/* <img className="about-me-blob-1" src={BOTTOM_BLOB}></img> */}
            {/* <img className="about-me-blob-2" src={BOTTOM_BLOB}></img> */}
            <div className="about-me-desc">
                <h1 className="i-am">I am...</h1>
                <div className="about-me-desc-2">
                    <ul>
                        <li>A passionate, innovative programmer with a talent for crafting captivating and purposeful digital experiences.</li>
                        <li>Proficient in Front-End Web & Mobile Development, Application Development, and driven by a deep enthusiasm for <span className="game-dev">Game Development.</span></li>
                        <li>A dynamic and charismatic individual dedicated to empowering individuals and teams to achieve greatness.</li>
                    </ul>
                </div>
                <div onClick={this.scrollToAboutMe} className="arrow-down-abt-me"><img className="bottom-arrow" src={DOWN_ARROW}></img></div>
            </div>
            
        </div>
    }
}

export default AboutMe;