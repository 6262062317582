import React, { Component } from "react";
import FirstPage from "./HomeComponents/FirstPage";
import NewestProject from "./HomeComponents/NewestProject";
import AboutMe from "./AboutMe";
import Footer from "./Footer";

class Home extends Component{

    constructor(props){
        super(props);
    }

    render(){
        return <div>
            <FirstPage />
            <AboutMe />
            <NewestProject />
            <Footer />
        </div>;
    }
}

export default function(props){
    return <Home {...props} />
};