import React, { Component } from "react";
import "./FirstPageStyle.css";
import BOTTOM_BLOB from "../media/bottom_blob.svg";
import TOP_BLOB from "../media/top_blob.svg";
import DOWN_ARROW from "../media/down_arrow.svg";

class FirstPage extends Component{

    constructor(props){
        super(props);
    }

    scrollToAboutMe(){
        var element = document.getElementById("about");
        window.scrollTo({
            top:element.offsetTop,
            behavior:"smooth"
        });
    }

    scrollToProjects(){
        var element = document.getElementById("projects");
        window.scrollTo({
            top:element.offsetTop,
            behavior:"smooth"
        });
    }

    setContactVisibility(){
        var element = document.getElementById("contact");
        var element2 = document.getElementById("fade");
        element.style.display = "block";
        element2.style.display = "block";
    }

    removeContactVisibility(){
        var element = document.getElementById("contact");
        var element2 = document.getElementById("fade");
        element.style.display = "none";
        element2.style.display = "none";
    }

    

    render(){
        return <div id="top" className="first-page">
            <div className="container">
                <span className="header"><h1 className="header-name">Alexander Lamarche 
                </h1><span className="header-position">Software Engineer</span>
                </span>
                <div className="nav">
                    <ul>
                        <li onClick={this.scrollToAboutMe}>About</li>
                        <li onClick={this.scrollToProjects}>Projects</li>
                        <li onClick={this.setContactVisibility}>Contact</li>
                    </ul>
                </div>
            </div>
            <div onClick={this.scrollToAboutMe} className="arrow-down"><img className="bottom-arrow" src={DOWN_ARROW}></img></div>
            {/* <img className="bottom-blob" src={BOTTOM_BLOB}></img> */}
            {/* <img className="top-blob" src={TOP_BLOB} /> */}
            <div id="fade" className="background-fade"></div>
            <div id="contact" className="contact-modal">
                <p className="email">alexlamarche90@gmail.com</p>
                <a href="https://www.linkedin.com/in/alex-lamarche/" target="_blank">www.linkedin.com/in/alex-lamarche/</a>
                <div className="contact-close" onClick={this.removeContactVisibility}>close</div>
            </div>
        </div>;
    }
}

export default FirstPage;