import React, { Component } from "react";
import "./ProjectCardStyle.css";
import {useNavigate} from "react-router-dom";
import ARROW from "../media/back-arrow.png";
import Skill from "./Skill";

class ProjectCard extends Component{
    
    handleClick = () => {
        console.log(this.props.projectID);
        this.props.navigate("/project", {state:{projectID: this.props.projectID, skills: this.props.skills}});
    }
    
    render(){
        return <div onClick={this.handleClick} className="project-card">
            <div className="card-info">
                <img src={this.props.img}/>
                <div className="skills-used">
                    <h1>{this.props.name}</h1>
                    {this.props.skills.map((skill,i)=><Skill i={i} skill={skill}/>)}
                </div>
                <p className="desc-text">{this.props.desc}</p>
            </div>
            <p className="see-more"><img src={ARROW} /></p>
        </div>;
    }
}


export default function(props) {
    const navigate = useNavigate();
    return <ProjectCard {...props} navigate={navigate} />;
}
