import React, {Component} from "react";
import "./FooterStyle.css";
import BOTTOM_BLOB from "./media/bottom_blob.svg";
import DOWN_ARROW from "./media/down_arrow.svg";

class Footer extends Component{
    constructor(props){
        super(props);
    }

    scrollToTop(){
        var element = document.getElementById("top");
        window.scrollTo({
            top:element.offsetTop,
            behavior:"smooth"
        });
    }

    render(){
        return <div className="footer-body">
            {/* <img className="footer-blob" src={BOTTOM_BLOB}></img>' */}
            <div onClick={this.scrollToTop} className="arrow-to-top">
                <img className="bottom-arrow" src={DOWN_ARROW}></img>
            </div>
            <div id="footer" className="footer-content">
            </div>
        </div>;
    }
}

export default Footer;