import React, { Component} from "react";
import "./NewestProjectStyle.css";
import BOTTOM_BLOB from "../media/bottom_blob.svg";
import ProjectCard from "./ProjectCard";

import {db} from '../firebase/Firebasedata';
import {collection, getDocs, orderBy,query} from 'firebase/firestore';

class NewestProject extends Component{
    
    constructor(props){  
        super(props);
        this.state = {
            loadedProjects: false,
            projects: [],
            skills:[]
        };
    }  

    async getProjects(){
        this.state.projects = [];
        const querySnapshot = await getDocs(query(collection(db,"Projects"),orderBy("order")));
        querySnapshot.forEach((d)=>{
            this.state.projects.push(d);
        });
        this.setState({loadedProjects:true});
    }

    componentDidMount () {
        this.getProjects();
    }
    
    render(){
        return <div id="projects" className="second-page">
            <div className="second-page-container">
                <h2 className="project-title">Projects</h2>
                <div className="project-grid">
                    {
                        this.state.loadedProjects ? this.state.projects.map((project,i) => 
                            <ProjectCard 
                            key={i}
                            name={project.data().name}
                            desc={project.data().desc}
                            img={project.data().cover_img}
                            projectID={project.id}
                            skills={project.data().skills}/>) 
                        : <div></div>
                    }

                </div>
                
            </div>
            {/* <img className="bottom-blob-newest-project" src={BOTTOM_BLOB}></img> */}
        </div>;
    }
}

export default NewestProject;