import React, {Component} from "react";
import "./SkillStyle.css";

class Skill extends Component{
    constructor(props){
        super(props);
    }
    render(){
        return <div key={this.props.i} className="skill-chip">{this.props.skill}</div>;
    }
}

export default Skill;