import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBftgfAMIZZBJABf3bTgppUdp7VnVO4cL4",
  authDomain: "alex-portfolio-119a6.firebaseapp.com",
  projectId: "alex-portfolio-119a6",
  storageBucket: "alex-portfolio-119a6.appspot.com",
  messagingSenderId: "51410049219",
  appId: "1:51410049219:web:20f45a32035455ed5e2a76",
  measurementId: "G-536E8NQWVR"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export {db}